import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_currency_bar = _resolveComponent("currency-bar")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.Prices, (moment) => {
    return (_openBlock(), _createElementBlock("div", { key: moment }, [
      _createVNode(_component_currency_bar, {
        value: moment[1],
        time: moment[0]
      }, null, 8, ["value", "time"])
    ]))
  }), 128))
}