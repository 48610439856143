
import { defineComponent, PropType } from "vue";
import dateManager from "@/components/Datemanager.vue";
import CoinHistory from "./CoinHistory.vue";
import Statistics from "./statistics.vue";
import History from "../type/market";
import TimeMachine from "./TimeMachine.vue";

export default defineComponent({
  components: { dateManager, CoinHistory, Statistics, TimeMachine },
  data() {
    return {
      startDate: 0,
      endDate: 0,
      history: [] as PropType<History>,
      historyLoaded: false,
    };
  },
  methods: {
    /**
     * history array is populated using fetchMarketChartRange function every time dates are changed.
     * fetchMarketChartRange function inserts the data into history nested array.
     * History nested array uses History interface created in type/market.ts file. With Vetur addon in vs code
     * you can then navigate the nested array like "history.prices" or "history.market_caps".
     * This allows easy re-use of data.
     * Future version would have own class where functions for CoinGecko api would be collected and imported from.
     */
    dateChanged(start: number, end: number) {
      this.startDate = start;
      this.endDate = end;
      this.fetchMarketChartRange("bitcoin", this.startDate, this.endDate);
    },
    fetchMarketChartRange(
      id: string,
      from: number,
      to: number,
      vs_currency = "eur"
    ) {
      const apiAdress = "https://api.coingecko.com/api/v3/";
      const path = `/coins/${id}/market_chart/range/?vs_currency=${vs_currency}&from=${from}&to=${to}`;
      fetch(`${apiAdress + path}`).then((res) =>
        res
          .json()
          .then((data) => (this.history = data))
          .then(() => (this.historyLoaded = true))
          .catch((err) => console.log(err.message))
      );
    },
  },
});
