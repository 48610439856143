
import { defineComponent, PropType } from "vue";
import Prices from "../type/market";
import CurrencyBar from "./currencyBar.vue";

export default defineComponent({
  components: { CurrencyBar },
  props: {
    Prices: {
      required: true,
      type: [] as PropType<Prices>,
    },
  },
});
