import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1108210a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-selector-container" }
const _hoisted_2 = { class: "startDate" }
const _hoisted_3 = { class: "endDate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_picker = _resolveComponent("date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_date_picker, {
        onDateChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startDate = $event))
      }),
      _createElementVNode("p", null, "picked start date in epoch format: " + _toDisplayString(_ctx.startDate), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_date_picker, {
        onDateChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.endDate = $event + 3600 * 24))
      }),
      _createElementVNode("p", null, "picked end date in epoch format: " + _toDisplayString(_ctx.endDate), 1)
    ])
  ]))
}