import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("p", null, " Longest bearish trend within time period: " + _toDisplayString(_ctx.calculateBearishTrend(this.history.prices)) + " in days. ", 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", null, " Date for with largest trade volume: " + _toDisplayString(_ctx.getDateWithLargestTradingVolume(this.history.market_caps)[0]) + ", Amount traded: " + _toDisplayString(_ctx.getDateWithLargestTradingVolume(this.history.market_caps)[1]) + " Euros worth ", 1)
    ])
  ], 64))
}