
/**
 * This component gets the time range for the app.
 * Start and end dates are in epoch format and emitted for parent component to use.
 * parent component being container in this project.
 */

import { defineComponent } from "vue";
import DatePicker from "@/components/DatePicker.vue";

export default defineComponent({
  components: { DatePicker },
  data() {
    return {
      startDate: 0,
      endDate: 0,
    };
  },
  watch: {
    startDate: function (newVal) {
      // only emit dates if end date is also set to avoid unnecessary fetch from api
      if (this.endDate != 0) {
        this.$emit("dates", newVal, this.endDate);
      }
    },
    endDate: function (newVal) {
      this.$emit("dates", this.startDate, newVal);
    },
  },
});
